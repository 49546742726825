import React, { createContext, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore } from "firebase/firestore";

export const AppContext = createContext();

// Initialize Firebase
const app = initializeApp({
    apiKey: "AIzaSyCD3_iBrpfjUzc4Ne9A_A0uzpbeup9q7XE",
    authDomain: "merqury-4fe31.firebaseapp.com",
    projectId: "merqury-4fe31",
    storageBucket: "merqury-4fe31.appspot.com",
    messagingSenderId: "646390476986",
    appId: "1:646390476986:web:8d3422b0968309dba31283",
    measurementId: "G-G0RM2EQHW6"
});
initializeFirestore(app, { experimentalAutoDetectLongPolling: true });
if (process.env.NODE_ENV !== "development")
    getAnalytics(app);

export const AppContextProvider = props => {

    const [showContactModal, setShowContactModal] = useState(false);
    const [showLegalModal, setShowLegalModal] = useState(false);
    const [mobileToggle, setMobileToggle] = useState(false);

    const sendMessage = async (name, email, phone, company, message, marketingConsent) => {
        if (!name.length || !email.length)
            return { error: true, msg: "Name and email can't be empty" };
        if (message.length < 10)
            return { error: true, msg: "Message must be at least 10 characters" };

        try {
            const resp = await fetch(
                process.env.NODE_ENV !== "development"
                    ? `https://api.merqury.co.uk/database/common/saveWaitListEntry`
                    : `http://localhost:3131/database/common/saveWaitListEntry`
                , {
                    method: 'POST', cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: name,
                        email: email,
                        phone: phone,
                        company: company,
                        message: message,
                        marketingConsent: marketingConsent,
                    })
                });
            const respJson = await resp.json();
            console.log("saveWaitListEntry respJson: ", respJson)
            if (respJson.error)
                return { error: true, msg: "Error saving your message! Try again..." };
            return { success: true, msg: "Message saved! You can close the popup" };

        } catch (e) {
            return { error: true, msg: "Error saving your message! Try again..." };
        }
    }

    return <AppContext.Provider value={{
        showContactModal, setShowContactModal,
        showLegalModal, setShowLegalModal,
        mobileToggle, setMobileToggle,
        sendMessage
    }} {...props} />
}