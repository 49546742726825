import React, { useContext } from 'react'
import styles from './CTAMerchant.module.css';
import Button from '../ui/Button';
import { AppContext } from '../../AppContext';

const CTAMerchant = ({ mode }) => {

    const appContext = useContext(AppContext);
    const isSchool = mode === "school";
    const isOms = mode === "oms";

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>

                <img className={styles['three-phones']} alt="3-phones" src={isSchool ? require('../../assets/images/school-apps.png') : require('../../assets/images/3-apps.png')} />
                <div style={{ flexBasis: '50%' }} />

                <div className={styles['text-section']}>
                    <span className={styles['title']}>{isSchool ? `Try Merqury now, or schedule a demo` : (isOms ? `Try It Now Free` : `Get The App`)}</span>
                    <span className={styles['desc']}>{
                        isSchool
                            ? `Sign up and investigate our features by yourself, or get in touch with our friendly team to arrange a walkthrough.`
                            : (isOms
                                ? `Try Merqury Order Management system for free today, click below to sign up in 5 minutes.`
                                : `Sign up to Merqury to end high card fees for your business. Download our app and start using Merqury.`
                            )
                    }
                    </span>
                    {(!isSchool && !isOms) && <div className={styles['app-download-links']}>
                        <a href="https://apps.apple.com/us/app/merqury-sales/id6474265785" target="_blank" rel="noreferrer">
                            <img alt="store-logo" src={require(`../../assets/images/apple-appstore.png`)} />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=uk.co.merqury.merchant" target="_blank" rel="noreferrer" >
                            <img alt="store-logo" src={require(`../../assets/images/google-play.png`)} />
                        </a>
                    </div>}

                    {(isSchool || isOms) && <div className={styles['app-download-links']}>
                        <Button text="Enquire" wrapperClass={styles.button} onClick={() => { appContext.setShowContactModal(true) }} />
                        <Button text="Try" wrapperClass={styles.button} onClick={() => {
                            let urlToRedirect = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                                ? `https://merchant-staging.merqury.co.uk/register`
                                : `https://merchant.merqury.co.uk/register`;
                            window.location.href = urlToRedirect
                        }} />
                    </div>}
                </div>
            </div>
        </div>)
}

export default CTAMerchant