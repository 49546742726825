import React from 'react';
import styles from './HowItWorksFeaturesConsumer.module.css';
import { BsCashCoin } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';
import { FaUserFriends } from 'react-icons/fa'

const FEATURES_PERSONAL = [
    {
        title: "Get Paid by Anyone",
        desc: "Anyone can Merq you, even if they don’t have a Merqury account – all they need is a bank account. You can send a request to anyone.",
        icon: <BsCashCoin />
    },
    {
        title: "Take Payments in Person",
        desc: "Just show your QR code and say ‘Merq Me’. Split the bill at the restaurant, sell your stuff at a car boot sale, or collect utilities from housemates.",
        icon: <FaUserFriends />
    },
    {
        title: "Send Payment Links",
        desc: "Generate a payment link with a tap and share it with anyone who owes you easily. Charge one person or multiple people at the same time.",
        icon: <MdSend />
    }
]


const HowItWorksFeaturesConsumer = () => {

    let feature = FEATURES_PERSONAL;
    let oneRow = true;

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content ${oneRow ? styles['one-row'] : ""}`}>
                {
                    feature.map((item, idx) => {
                        return (
                            <div key={idx}>
                                <div className={styles.left}>
                                    <div className={styles['icon-wrapper']}>
                                        {item.icon}
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <span>{item.title}</span>
                                    <p>{item.desc}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default HowItWorksFeaturesConsumer