import React from 'react'
import styles from '../styles/MerchantPage.module.css';
import TopBar from '../../components/navigation/TopBar';
import Hero from '../../components/Hero';
import HowItWorksFeaturesSchool from '../../components/how-it-works/HowItWorksFeaturesSchool';
import Video from '../../components/Video';
import PricingSchool from '../../components/PricingSchool';
import Footer from '../../components/footers/Footer';
import CTAMerchant from '../../components/footers/CTAMerchant';

const SchoolPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" activeSubMenuItem="schools" />
            <Hero mode="school" />
            <HowItWorksFeaturesSchool />
            <Video mode="school" />
            <PricingSchool />
            <CTAMerchant mode="school" />
            <Footer />
        </div>
    )
}

export default SchoolPage