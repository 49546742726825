import React, { useState } from 'react'
import styles from './styles/Video.module.css';
import ToggleButton from './ui/ToggleButton';

const Video = ({ mode }) => {

    const embedId = mode === "school" ? "IJbRziBXqCo" : "bHiL70rZFxE";
    const isOms = mode === "oms";

    const [videoIdx, setVideoIdx] = useState(0);


    return (
        <div className={`${styles['main-content-wrapper']} ${(mode === "school" || mode === "oms" )? styles.school : ''}`}>
            <div className={`${styles.main} main-content`}>
                {mode === "school" && <h2 className={styles.h2}>See How It Works</h2>}
                {mode === "oms" && <h2 className={styles.h2}>See It in Action</h2>}
                {mode === "oms" &&
                    <ToggleButton labelArr={["Merchant View", "Customer View"]} activeLabelIdx={videoIdx}
                        onChange={(selectedIdx) => setVideoIdx(selectedIdx)}
                        wrapperStyles={{ maxWidth: '400px', width: '100%', margin: '0px auto 30px auto' }} />
                }
                {<iframe
                    src={`https://www.youtube.com/embed/${(isOms ? (videoIdx ? 'dtUeQFFo6BU' : 'oF5pTJKPcdI') : embedId)}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen
                    title="Embedded youtube"
                />}
            </div>
        </div>
    )
}

export default Video