import React from 'react'
import styles from './HowItWorksConsumer.module.css';

const STEPS =
{
    consumer: [
        {
            title: "Step 1: Request a Payment",
            desc: "Open your Merqury app and tap ‘Request Merq’."
        },
        {
            title: "Step 2: Share your Link",
            desc: "Enter the amount that you’re owed and tap ‘Generate QR’, or ‘Send Link’."
        },
        {
            title: "Step 3: Merq Me",
            desc: "The payer either scans your QR code or taps the link you send them and approves the payment."
        },
        {
            title: "Step 4: Instant Settlement",
            desc: "That’s it! Money is in your bank instantly, and you both get a confirmation message."
        },
    ]
}

const HowItWorksConsumer = () => {

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <div className={styles['top-section']}>
                    <div className={styles.left}>
                        <h2>How It Works</h2>
                        <span className={styles.desc}>
                            A Merq is an instant bank transfer which moves money securely from someone else’s bank account directly into your bank account, only with their explicit approval.<br/> <br/>After you request a Merq, your payer will either tap the link you send them or scan your personal QR code. They will be taken to their bank app to approve the payment, and that’s it! The money is yours. No need to copy down bank details or chase payment from your friends. See below for a step by step.
                        </span>
                    </div>
                    <div className={styles.right}>
                        <img alt="screenshot" src={require(`../../assets/images/how-it-works-header-consumer.png`)} />
                    </div>
                </div>
                <div className={styles['bottom-section']}>
                    {
                        STEPS["consumer"].map((item, idx) => {
                            return (
                                <div key={idx} className={styles['grid-item']}>
                                    <div className={styles.left}>
                                        <img alt="step" src={require(`../../assets/images/how-it-works/merchant/step-${idx}-new.png`)} />
                                    </div>
                                    <div className={styles.right}>
                                        <span>{item.title}</span>
                                        <span>{item.desc}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default HowItWorksConsumer