import React from 'react'
import styles from './styles/ConsumerPage.module.css';
import TopBar from '../components/navigation/TopBar';
import Hero from '../components/Hero';
import HowItWorksConsumer from '../components/how-it-works/HowItWorksConsumer';
import Footer from '../components/footers/Footer';
import HowItWorksFeaturesConsumer from '../components/how-it-works/HowItWorksFeaturesConsumer';
import Network from '../components/footers/Network';
import CTAConsumer from '../components/footers/CTAConsumer';

const ConsumerPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="personal" />
            <Hero mode="personal" />
            <HowItWorksFeaturesConsumer/>
            <HowItWorksConsumer/>
            <Network/>
            <CTAConsumer/>
            <Footer />
        </div>
    )
}

export default ConsumerPage