import React from 'react'
import styles from './Network.module.css';

const Network = props => {
    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>

                <div className={styles['top-section']}>
                    <h2>Networks</h2>
                    <span className={styles.desc}>
                        Merqury works with all major card networks and UK banks
                    </span>
                </div>

                <div className={styles['bottom-section']}>
                    {[...Array(8)].map((_, idx) => {
                        return (
                            <img alt="logo" src={
                                require(`../../assets/images/network-logos/${idx+1}.png`)
                            }/>
                        )
                    })}
                    <span>... and more</span>
                </div>
            </div>
        </div>
    )
}

export default Network