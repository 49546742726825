import React, { useEffect, useState } from 'react'
import styles from './styles/Testimonials.module.css';
import { MdOutlinePermIdentity } from 'react-icons/md';

const QUOTES = [
    {
        quote: "I love that I get money in my bank account straight away, I ask all my fares to pay with Merqury now",
        name: "Ansar",
        job: "Taxi driver"
    },
    {
        quote: "I am so glad that I signed up for Merqury. I find sending invoices time-consuming and inconvenient. It’s great to be able to send a simple payment link and I find that I get paid more promptly.",
        name: "Andrea",
        job: "Child Sleep Specialist"
    },
    {
        quote: "Yeah that’s solid! So quick and simple. Much easier. Better way to do payments",
        name: "Lorenzo",
        job: "Personal Trainer"        
    },
    {
        quote: "This is super super helpful, we had issues on our old app, this looks great!",
        name: "Darcey",
        job: "Print Shop"        
    }
]

const Testimonials = props => {

    const [quoteIdx, setQuoteIdx] = useState(0);

    useEffect(() => {
        const handler = setTimeout(() => setQuoteIdx(prev => prev + 1 >= QUOTES.length ? 0 : prev + 1), 5000);
        return () => clearTimeout(handler);
    },[quoteIdx])

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>

                <div className={styles['top-section']}>
                    <h2>Testimonials</h2>
                    <span className={styles.desc}>
                        What customers are saying about Merqury
                    </span>
                </div>
                <div className={styles['bottom-section']}>
                    <div className={styles['quote-wrapper']}>
                        <div className={styles['avatar']}><MdOutlinePermIdentity /></div>
                        <span className={styles['quote']}>“{QUOTES[quoteIdx].quote}”</span>
                        <span className={styles['name']}>{QUOTES[quoteIdx].name}</span>
                        <span className={styles['job']}>{QUOTES[quoteIdx].job}</span>
                    </div>
                    <div className={styles['quote-carousel']}>
                        {QUOTES.map((_, idx) => {
                            return (
                                <div key={idx} onClick={() => setQuoteIdx(idx)}>
                                    <div className={`${styles['idx']} ${idx === quoteIdx ? styles['active'] : ''}`}><MdOutlinePermIdentity /></div>
                                </div>
                            )
                        })

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials