import React from 'react'
import styles from './HowItWorksMerchant.module.css';


const STEPS =
{
    merchant: [
        {
            title: "Pay by Bank App In-person",
            desc: "Take an in-person payment from a customer in just three quick steps. Get money in your account instantly and pay just 0.4% per transaction."
        },
        {
            title: "Take a card payment in-person",
            desc: "Ditch the card terminal and take card payments straight from your phone with Merqury. You have the option to access funds instantly."
        },
        {
            title: "Send a payment link to a customer anywhere",
            desc: "Merqury allows you to take either a Bank App or card payment from anywhere with a simple link. Choose whether you want to enable card payments on each link."
        },
        {
            title: "Generate payment links  automatically with Xero",
            desc: "If you use Xero for invoicing, use our handy Merqury plug-in to automatically generate a Merqury link on each invoice and make paying a doddle for your customers. Get paid faster for less."
        },
    ]
}

const STEPS_OMS =
{
    merchant: [
        {
            title: "Customer Scans or Clicks",
            desc: "Your customer just needs to scan your Merqury QR Code with their mobile phone. Or you can share a link that they can click."
        },
        {
            title: "They Choose What They Want",
            desc: "The customer can peruse your menu at their leisure and add items to their cart, including add-ons and customisations."
        },
        {
            title: "Merqury Takes Payment",
            desc: "The customer pays for their order on their phone. They can leave a tip, and we even give them a downloadable receipt."
        },
        {
            title: "Complete Order and Notify",
            desc: "Once the order is ready, just mark it as complete in your Merqury app, and the customer gets notified to come pick it up."
        },
    ]
}

const HowItWorksMerchant = ({ mode }) => {

    const isOms = mode === "oms";

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <div className={styles['top-section']}>
                    <h2>How This Works</h2>
                    <span className={styles.desc}>
                        {isOms
                            ? `See how to use Merqury Order Management to make ordering as easy as pie for your customers`
                            : `See how to use Merqury to take fast, secure, low-fee payments.`
                        }
                    </span>
                </div>
                <div className={styles['bottom-section']}>
                    {
                        (isOms ? STEPS_OMS["merchant"] : STEPS["merchant"]).map((item, idx) => {
                            return (
                                <div key={idx} className={styles['step-item']}>
                                    <div className={styles['text-section']}>
                                        <span className={styles['idx']}>{idx + 1}</span>
                                        <span className={styles['title']}>{item.title}</span>
                                        <p className={styles['desc']}>{item.desc}</p>
                                    </div>
                                    <div className={styles['image-section']}>
                                        {isOms
                                            ? <img alt="step" src={require(`../../assets/images/how-it-works/merchant/feat-oms-${idx + 1}.png`)} />
                                            : <img alt="step" src={require(`../../assets/images/how-it-works/merchant/feat-${idx + 1}.png`)} />
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default HowItWorksMerchant