import React, { useContext, useEffect, useState } from 'react'
import styles from './ContactUsModal.module.css';
import { AiOutlineUser, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { MdBusiness } from 'react-icons/md';
import { BiMessageAlt } from 'react-icons/bi';
import { AppContext } from '../../AppContext';

const InputItem = ({ label, value, setValue, icon, isOptional, isTextArea }) => {
    return (
        <div className={styles['input-wrapper']}>
            {icon}
            {isTextArea
                ? <textarea placeholder={`${label}${isOptional ? " (Optional)" : ""}`} value={value} onChange={(e) => setValue(e.target.value)} />
                : <input type="text" placeholder={`${label}${isOptional ? " (Optional)" : ""}`} value={value} onChange={(e) => setValue(e.target.value)} />
            }
        </div>
    )
}

const ContactUsModal = props => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const appContext = useContext(AppContext);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const handleClick = async () => {
        setErrorText("")
        let res = await appContext.sendMessage(name, email, phone, company, message, true);
        if (res.error) {
            setErrorText(res.msg)
        } else {
            setSuccessText(res.msg)
        }
    }

    useEffect(() => {
        setErrorText("");
    }, [name, email, phone, company, message])

    return (
        <div className={`modal`}>
            <div className={`modal-content`}>
                <div className={`modal-close`} onClick={() => appContext.setShowContactModal(false)}>X</div>
                <h2>Get in touch</h2>
                <InputItem label="Name" value={name} setValue={setName} icon={<AiOutlineUser />} />
                <InputItem label="Email" value={email} setValue={setEmail} icon={<AiOutlineMail />} />
                <InputItem label="Phone Number" value={phone} setValue={setPhone} icon={<AiOutlinePhone />} isOptional={true} />
                <InputItem label="Company Name" value={company} setValue={setCompany} icon={<MdBusiness />} isOptional={true} />
                <InputItem label="Your Message..." value={message} setValue={setMessage} icon={<BiMessageAlt />} isTextArea={true} />
                {errorText && <div className='error-card'>{errorText}</div>}
                {successText && <div className='success-card'>{successText}</div>}
                <div className={styles["button-row"]}>
                    {!successText && <div onClick={handleClick}>Send now</div>}
                    <div onClick={() => appContext.setShowContactModal(false)}>Close</div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsModal