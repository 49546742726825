import React, { useEffect, useState } from 'react'
import styles from './styles/Faq.module.css';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

const QUESTIONS = [
    {
        question: "How do 'Pay by Bank App' work?",
        answerComponent: <p>Merqury takes the middleman out of making a payment by routing money directly from your customer’s bank account to your bank account. We initiate an instant bank transfer, meaning you get the money immediately and pay a much lower fee since you are not paying to use card networks.</p>
    },
    {
        question: "How much do 'Pay by Bank App' transfers cost?",
        answerComponent: <p>You pay just 0.4% per transaction, that's it! There are no subscription fees, gateway fees, success fees, or any other fees of any other kind. You can also get volume discount and pay as low as 0.2%, just reach out to us via hello@merqury.co.uk</p>
    },
    {
        question: "How much do card payments cost?",
        answerComponent: <>
            <p>In-person card payments cost 1.0% per transaction on QR Pay and 1.6% + 25p for Tap to Pay.</p>
            <p>Most card payment links cost 1.5% +20p, but some payments will cost more depending on the type of card being used:</p>
            <table>
                <tbody>
                    <tr>
                        <td>UK standard debit and credit cards</td>
                        <td>1.5% + 20p</td>
                    </tr>
                    <tr>
                        <td>UK corporate cards and American Express cards</td>
                        <td>1.9% + 20p</td>
                    </tr>
                    <tr>
                        <td>Non-UK, European Economic Area cards</td>
                        <td>2.5% + 20p</td>
                    </tr>
                    <tr>
                        <td>Non-UK, Rest of World cards</td>
                        <td>3.25% + 20p</td>
                    </tr>
                    <tr>
                        <td>Currency Conversion to GBP for Non-UK cards paying in a different currency</td>
                        <td>+2%</td>
                    </tr>
                </tbody>
            </table>
        </>
    },
    {
        question: "What customers can use 'Pay by Bank App' transfers?",
        answerComponent: <><p>Merqury works with all UK banks (except Metro Bank). To use Merqury, your customer must:</p>
            <ul>
                <li>Have a smartphone with their bank’s mobile app installed.</li>
                <li>Use a regulated UK bank account (other than Metro Bank).</li>
            </ul>
        </>
    },
    {
        question: "How do I take an 'Pay by Bank App' transfer with Merqury?",
        answerComponent: <><p>You have three options to take a payment with Merqury:</p>
            <ul>
                <li>Ask your customer to scan the QR code shown on your phone when you initiate a transaction..</li>
                <li>Upon request, we can provide you with a physical QR card which you can put wherever you like for your customer to scan.</li>
                <li>You can order a Merqury Tag which your customer can tap with their phone to pay.</li>
            </ul>
        </>
    },
    {
        question: "Does Merqury replace my card machine?",
        answerComponent: <>
            <p>Merqury can replace your card machine by turning your phone into a card machine so that you can take instant bank transfers and card payments all from the Merqury app. Remember there is no subscription fee so there is no cost to doing so.</p>
        </>
    },
    {
        question: "Does Merqury have any contracts or account fees?",
        answerComponent: <p>No. You will only pay per transaction processed using our platform. There are no sign-up fees, contract fees, or other costs of any kind. There is no minimum contract, you can sign up from your phone and start using Merqury immediately. You can close your account any time you like (but with no subscription fee, why would you?).</p>
    },
    {
        question: "How do I start using Merqury?",
        answerComponent: <p>Once you have registered in the app or via the website, you can start taking payments as soon as your identity has been verified. Register now <a href="https://merchant.merqury.co.uk/" target="_blank">online</a>, or download our iPhone app from the <a href="https://apps.apple.com/app/merqury-sales/id6474265785" target="_blank">App Store</a>, or our Android app from the <a href="https://play.google.com/store/apps/details?id=uk.co.merqury.merchant" target="_blank">Play Store</a>.</p>
    },
    {
        question: "How quickly will I receive the money?",
        answerComponent:
            <>
                <p>The days of waiting around for your cash are gone. 'Pay by Bank App' transfers pay out into your nominated bank account within seconds after a transaction.</p>
                <p>Card payments are subject to a 5-day payout period. </p>
            </>
    },
    {
        question: "Why am I only just hearing about Merqury?",
        answerComponent: <p>
            Due to recent changes in the banking regulations that enable Open Banking services. You may have noticed while shopping online that you are sometimes asked to open your banking app to approve payments, or you might see a pop-up on your phone when you spend money in a store. Merqury uses the same technology to initiate bank to bank transfers. Read more about Open Banking here: <a href="https://www.openbanking.org.uk/what-is-open-banking/" target="_blank">https://www.openbanking.org.uk/what-is-open-banking/</a>
        </p>
    },
    {
        question: "Does Merqury have access to my bank account?",
        answerComponent: <p>
            No. When you register with Merqury you will be asked to connect your bank account. We use this to verify your identity, so that we know where to route payments, and to verify that payments have settled. We are not able to access funds in your account. In fact, we don’t even handle funds in transit as your customer’s money is deposited directly into your account (it doesn’t go via Merqury).
        </p>
    },
    {
        question: "Does Merqury have access to my customer’s bank account?",
        answerComponent: <p>
            No. The payment is made from the customer’s mobile banking app, nor from the Merqury app. The customer does not need to install Merqury, and we do not have access to their account information. We may invite the customer to register for a consumer account with Merqury if they wish to use additional features, but this is strictly optional.
        </p>
    },
    {
        question: "Why do I see Yapily on some screens?",
        answerComponent: <p>
            Yapily is our trusted payments partner. Yapily is authorised and regulated by the Financial Conduct Authority (FCA # 827001). You can read their T&Cs on their website: <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">https://www.yapily.com/legal/end-user-terms</a>
        </p>
    },
    {
        question: "How are my customers’ payment details handled and stored?",
        answerComponent: <p>
            Your customer pays their bill by sending a bank transfer from their bank account. Their private bank information is never exposed or stored, even by Merqury.
        </p>
    },
    {
        question: "How do I know Merqury is secure?",
        answerComponent: <>
            <p>
                Merqury is a Limited Company registered in England and Wales with Company Number 14889545. Merqury only works with banks and e-money institutions authorised and regulated by the Financial Conduct Authority. You can check whether a company is regulated here: <a href="https://register.fca.org.uk/" target="_blank">https://register.fca.org.uk/</a>. Payment Initiation Services are provided by our partner Yapily Connect Ltd. Yapily is authorised and regulated by the Financial Conduct Authority (FCA # 827001). You can read their T&Cs on their website: <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">https://www.yapily.com/legal/end-user-terms</a>.
            </p>
            <p>Merqury uses industry leading encryption protocols (e.g., SSL/TLS) to protect user data in transit between users and servers. For user authentication, passwords are hashed before being stored. Merqury does not store plain-text passwords, which helps protect user data. </p>
            <p>Merqury operates a two step KYC/KYB process. Firstly Merqury utilises Open Banking technology to verify a businesses bank account ownership (AIS). This process ensures that a business has correctly passed the underlying banks internal KYB checks. Secondly, Merqury conducts its own proprietary and strict customer verification including but not limited to ID documentation as well as reference checks. Should a business wish to change any information, this will trigger a new KYB process which will require approval before transacting further.</p>
            <p>Merqury monitors all transactions in real-time with enhanced fraud detection systems in place that help identify suspicious transactions and mitigate associated payment risks. Merqury provides continuous customer support for any security-related issues or concerns.</p>
            <p>Payments made via Open Banking require customers to authenticate through their mobile or online banking <a href="https://www.fca.org.uk/firms/strong-customer-authentication" target="_blank">(Strong Customer Authentication)</a>.</p>
            <p>Payments made via Merqury use the Faster Payments scheme which is the scheme used by all UK Banks for most bank to bank transfers. Therefore, Merqury customers will benefit from the same protections that exist when making any other type of bank transfer. For more information see <a href="https://www.wearepay.uk/" target="_blank">https://www.wearepay.uk/</a> which is the operator of the Faster Payments scheme</p>
        </>
    },
    {
        question: "How secure are Merqury's payment links?",
        answerComponent: <p>
            Payment links can only be generated by KYB approved businesses. This means that unless a business has passed Merqury’s two step KYB process, they will be unable to send payment links. All Merqury single payment links are unique and can only be generated by authenticated users. Payment link bank account information can not be altered by businesses themselves or third parties.
        </p>
    },
]

const Faq = props => {

    const [openIdx, setOpenIdx] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/pricing-card")) {
            setOpenIdx(2)
        } else if (location.pathname.includes("/pricing-bt")) {
            setOpenIdx(1)
        } else if (location.pathname.includes("/security")) {
            setOpenIdx(15)
        }
    }, [location])

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <span className={styles.tag}>
                    <div className={styles['circle-1']}></div>
                    <div className={styles['circle-2']}></div>
                </span>
                <h1>Frequently Asked Questions</h1>

                {/*QUESTIONS*/}
                {QUESTIONS.map((item, idx) => {
                    return (
                        <div key={idx} className={styles['qa_card']}>
                            <div className={styles['question-wrapper']} onClick={() => { setOpenIdx(idx === openIdx ? null : idx) }}>
                                <span>{item.question}</span>
                                {idx === openIdx && <BsChevronUp className={styles.icon} />}
                                {idx !== openIdx && <BsChevronDown className={styles.icon} />}
                            </div>
                            <div className={`${styles['answer-wrapper']} ${idx === openIdx ? styles.open : ""}`}>
                                {item.answerComponent}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Faq