import React, { useContext, } from 'react'
import styles from './TopBar.module.css';
import Button from '../ui/Button';
import { HiMenuAlt2 } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const TopBar = ({ activeMenuItem, activeSubMenuItem }) => {

    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const MENU_ITEMS = [
        {
            text: "Business", key: "business", action: () => { appContext.setMobileToggle(false); navigate("/business") },
            subMenu: [
                { text: "Education", key: "schools", action: () => { appContext.setMobileToggle(false); navigate("/education") } },
                { text: "Order Management", key: "oms", action: () => { appContext.setMobileToggle(false); navigate("/oms") } },
                { text: "FAQ", key: "faq", action: () => { appContext.setMobileToggle(false); navigate("/faq") } },
                { text: "Pricing", key: "pricing", action: () => { appContext.setMobileToggle(false); navigate("/pricing") } },
            ]
        },
        { text: "Personal", key: "personal", action: () => { appContext.setMobileToggle(false); navigate("/personal") } },
        {
            text: "Contact Us", key: "contact", action: () => {
                appContext.setMobileToggle(false);
                appContext.setShowContactModal(true)
            }
        }
    ]

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <HiMenuAlt2 className={styles['menu-icon']} onClick={() => appContext.setMobileToggle(!appContext.mobileToggle)} />
                <img alt="logo" src={require(`../../assets/images/logo.png`)} onClick={() => navigate("/")} />
                <div className={`${styles['action-section']} ${appContext.mobileToggle ? styles.toggled : ""}`}>
                    <div className={styles.menu}>
                        {
                            MENU_ITEMS.map((item, idx) => {

                                const isChildrenActive = item?.subMenu?.filter((subItem) => activeSubMenuItem === subItem.key)?.length > 0

                                return (
                                    <div key={idx} className={`${activeMenuItem === item.key ? styles.active : ''} ${isChildrenActive ? styles['children-active'] : ''}`} onClick={item.action}>
                                        <span>{item.text}</span>
                                        <div className={styles['highight-border']}></div>
                                        {item?.subMenu?.length > 0 &&
                                            <div className={styles['submenu-container']}>
                                                {item.subMenu.map((subItem, idx2) => {
                                                    return <span key={idx2} className={activeSubMenuItem === subItem.key ? styles.active : ''}
                                                        onClick={(e) => { e.stopPropagation(); subItem.action() }}>
                                                        {subItem.text}
                                                    </span>
                                                })}
                                            </div>}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Button text="Join Now" wrapperClass={styles['register-button']} onClick={() => {
                        let urlToRedirect = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                            ? `https://merchant-staging.merqury.co.uk/register`
                            : `https://merchant.merqury.co.uk/register`;
                        window.location.href = urlToRedirect
                    }} />
                </div>
            </div>
        </div>
    )
}
export default TopBar