import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

/**
 * Toggle Radio Button Group
 * supports >=2 labels
 */

const ToggleButton = ({ wrapperStyles, labelArr, activeLabelIdx, onChange }) => {

    const [leftMargin, setLeftMargin] = useState(0);

    useEffect(() => {
        const itemCount = labelArr?.length;
        const stepSize = 100 / itemCount;
        setLeftMargin(stepSize * activeLabelIdx)
    }, [labelArr, activeLabelIdx])

    return (
        <div style={{ background: '#fff', borderRadius: '10px', padding: '3px', border: '1px solid var(--merquryDarkBlue)', ...wrapperStyles }}>
            <div style={{
                flex: 1, width: '100%', height: '100%', borderRadius: '10px', position: 'relative', cursor: 'pointer',
                display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'space-between'
            }}>

                {/*Animated Selection Slider*/}
                <motion.div style={{ position: 'absolute', width: (100 / labelArr.length) + '%', background: 'var(--merquryDarkBlue)', height: '100%', borderRadius: '8px', display: 'flex' }}
                    animate={{ left: leftMargin + "%" }} />

                {/*Labels*/}
                {labelArr.map((item, idx) => {
                    return (
                        <div key={idx} style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,
                            padding: '9px 4px', zIndex: 3,
                        }}
                            onClick={() => {
                                if (idx !== activeLabelIdx)
                                    onChange(idx)
                            }}>
                            <span style={{ fontSize: '13px', fontWeight: 600, color: activeLabelIdx === idx ? "#fff" : "var(--merquryDarkBlue)" }}>
                                {item}
                            </span>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}


export default ToggleButton