import React from 'react'
import styles from './styles/FaqPage.module.css';
import TopBar from '../components/navigation/TopBar';
import Footer from '../components/footers/Footer';
import Faq from '../components/Faq';
import CTAMerchant from '../components/footers/CTAMerchant';

const FaqPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" activeSubMenuItem="faq" />
            <Faq/>
            <CTAMerchant/>
            <Footer/>
        </div>
    )
}

export default FaqPage