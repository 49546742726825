import React from 'react'
import styles from '../styles/MerchantPage.module.css';
import TopBar from '../../components/navigation/TopBar';
import Hero from '../../components/Hero';
import HowItWorksFeatures from '../../components/how-it-works/HowItWorksFeaturesMerchant';
import HowItWorksMerchant from '../../components/how-it-works/HowItWorksMerchant';
import Video from '../../components/Video';
import CTAMerchant from '../../components/footers/CTAMerchant';
import Footer from '../../components/footers/Footer';
import Network from '../../components/footers/Network';

const OmsPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" activeSubMenuItem="oms" />
            <Hero mode="oms" />
            <HowItWorksFeatures mode="oms" />
            <HowItWorksMerchant mode="oms"  />
            <Video mode="oms" />
            <Network/>
            <CTAMerchant mode="oms" />
            <Footer />
        </div>
    )
}

export default OmsPage