import React from 'react';
import styles from './HowItWorksFeaturesMerchant.module.css';
import { MdOutlineCurrencyPound, MdOutlineHourglassEmpty, MdOutlineQuestionAnswer, MdOutlineShowChart, MdOutlineTimer, MdSupportAgent } from 'react-icons/md';
import { FaCashRegister, FaMoneyBill } from 'react-icons/fa'

const FEATURES = [
    {
        title: "Stop waiting for your cash",
        desc: "Use Merqury’s Pay by Bank App technology to get paid into your bank account instantly. Your customer just needs their own bank’s app on their phone.",
        icon: <MdOutlineHourglassEmpty />
    },
    {
        title: "Slash processing costs",
        desc: "Pay by Bank App costs just 0.4% per transaction. Or take old-fashioned card payment from our new-fashioned app by using your phone as a card terminal.",
        icon: <FaCashRegister />
    },
    {
        title: "No other nonsense",
        desc: "With Merqury there is no contract, no minimum term, no account fee, no ‘just because’ fee.",
        icon: <MdOutlineQuestionAnswer />
    },
    {
        title: "A* customer support",
        desc: "We are proud to offer the best customer support in the business. Every Merqury customer gets access to a human being in the UK when they have a question.",
        icon: <MdSupportAgent />
    }
];

const FEATURES_OMS = [
    {
        title: "Get more orders",
        desc: "With Merqury Order Management, your customers can place orders using your QR code or store link. No more walk-aways because of busy period queues!",
        icon: <MdOutlineShowChart />
    },
    {
        title: "Increase order value",
        desc: "Research shows that customers order more when they don't interact with a person. They find it easier to explore your whole menu at their convenience.",
        icon: <MdOutlineCurrencyPound />
    },
    {
        title: "Receive more tips",
        desc: "Tips used to be common back in the days of cash. We are bringing them back by making it super-easy to leave a tip, without the awkward interaction.",
        icon: <FaMoneyBill />
    },
    {
        title: "Save time",
        desc: "Merqury Order Management frees you up from taking orders, handling payment, and giving out receipts. We do that for you, allowing you to focus on the cooking.",
        icon: <MdOutlineTimer />
    }
]


const HowItWorksFeatures = ({ mode }) => {

    const isOms = mode === "oms";
    const feature = isOms ? FEATURES_OMS : FEATURES;

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                {
                    feature.map((item, idx) => {
                        return (
                            <div key={idx} className={`${styles['feature-card']} ${idx >= 2 ? styles['reverse'] : ''}`}>
                                <div className={styles['icon-wrapper']}>
                                    {item.icon}
                                </div>
                                <span className={styles['title']}>{item.title}</span>
                                <p className={styles['desc']}>{item.desc}</p>
                            </div>
                        )
                    })
                }
                <div className={styles['screenshot-wrapper']}>
                    <img alt="screenshot" src={isOms
                        ? require('../../assets/images/how-it-works/merchant/merchant-features-oms-ss.png')
                        : require('../../assets/images/how-it-works/merchant/merchant-features-ss.png')
                    } />
                </div>
            </div>
        </div>
    )
}

export default HowItWorksFeatures