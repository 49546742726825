import React from 'react'
import styles from './CTAConsumer.module.css';

const CTAConsumer = props => {
    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <img alt="hand" className={styles['cta-image']} src={require(`../../assets/images/hand.png`)} />
                <h2>Join Merqury</h2>
                <span>
                    Download Merqury today to try out the easier way to get paid.
                </span>
                <div className={styles['app-download-links']}>
                    <a href="https://apps.apple.com/us/app/merqury-sales/id6474265785" target="_blank" rel="noreferrer">
                        <img alt="store-logo" src={require(`../../assets/images/apple-appstore.png`)} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=uk.co.merqury.merchant" target="_blank" rel="noreferrer" >
                        <img alt="store-logo"  src={require(`../../assets/images/google-play.png`)} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CTAConsumer