import React from 'react'
import styles from './Button.module.css'

const Button = ({ text, onClick, wrapperClass, reverseColor }) => {
    return (
        <div className={`${styles.main} ${wrapperClass} ${reverseColor ? styles.reverse : ''}`} onClick={onClick}>
            {text}
        </div>
    )
}

export default Button