import React, { useContext } from 'react'
import styles from './styles/LegalModal.module.css';
import { AppContext } from '../AppContext';

const LegalPopup = props => {

  const appContext = useContext(AppContext)

  return (
    <div className={`modal`}>
      <div className={`modal-content`} style={{ maxWidth: '100%', height: '90%' }}>
        <div className={`modal-close`} onClick={() => appContext.setShowLegalModal(false)}>X</div>
        <h2>{appContext.showLegalModal == "privacy" ? "Privacy Policy" : "Terms of Services"}</h2>
        <div className={styles['legal-wrapper']}>
          {appContext.showLegalModal == "privacy" && printPrivacyPolicy()}
          {appContext.showLegalModal == "tos" && printTOS()}
        </div>
      </div>
    </div>
  )
}

const printPrivacyPolicy = () => {
  return (
    <>
      <span className={styles['header-style']}>Who We Are</span>
      <p>Merqury Payments Limited, trading as Merqury, is a limited company registered in England and Wales with company number 14889545 (“we”, “us”, “our”, or “Merqury”). Our registered address is 124 City Road, London, EC1V 2NX.
        You can contact us on hello@merqury.co.uk.</p>
      <span className={styles['header-style']}>About this Policy</span>
      <p>This Privacy Policy (the “policy”), together with our Terms of Service and any other documents referred to in it, describes the type of information that we collect from you through the use of our services or the use of our website at https://merqury.co.uk or any of its subdomains, and how that information may be used or disclosed by us. By using or accessing our website or our services, you agree to the collection, use and disclosure of information in accordance with this policy. The policy may change from time to time and your continued use of the website or services is deemed to be acceptance of such changes, so please check periodically for updates.
        Our website and services may contain links to third party websites that are not covered by this policy. We therefore ask you to review the privacy statements of other websites and applications to understand their information practices.
        If you have any questions or concerns about this policy, please contact us on hello@merqury.co.uk.</p>
      <span className={styles['header-style']}>Information We Collect</span>
      <p>Personal Information: When you access our website or use our services, we may collect personal information such as your name, contact information, payment details, and other relevant information necessary to deliver services to you.
        Usage Information: We may also collect non-personal information about your use of our services, such as your device information, IP address, browser type, and usage patterns. This information helps us improve our services and enhance the user experience.</p>
      <span className={styles['header-style']}>Use of Data</span>
      <p>Provision of Services: We use the personal information we collect to provide and facilitate the payment initiation services you request. This includes initiating payments on your behalf, verifying your identity, and processing transactions.
        Communication and Marketing: We may use your personal information to communicate with you regarding the services, including transaction notifications, updates, and customer support.
        Analytics and Improvements: We may use non-personal information for analytics purposes to understand how our services are used, identify trends, and improve our platform's functionality and performance.</p>
      <span className={styles['header-style']}>Data Sharing</span>
      <p>Service Providers: We may share your personal information with trusted third-party service providers who assist us in delivering our services. These service providers are contractually bound to protect your information and are authorised to use assigned data only for the purposes specified by us.
        Legal Compliance: We may disclose personal information if required by law, regulation, legal process, or government request. We may also disclose information to protect our rights, privacy, safety, or property, or that of our users, or to enforce our terms of service.</p>
      <span className={styles['header-style']}>Data Security</span>
      <p>We implement appropriate security measures to protect your personal information from unauthorised access, alteration, disclosure, or destruction. These measures may include encryption, firewalls, access controls, and regular security assessments.
        However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
      <span className={styles['header-style']}>Data Retention</span>
      <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required by law. After that, we will securely dispose of your information.</p>
      <span className={styles['header-style']}>Your Rights</span>
      <p>You have the right to access, correct, or delete your personal information that we hold. If you wish to exercise these rights or have any questions or concerns about your personal information, please contact us using the information provided at the end of this policy.</p>
      <span className={styles['header-style']}>Updates to this Policy</span>
      <p>We may update this policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the updated policy on our website or through other means of communication.</p>
      <span className={styles['header-style']}>Contact Us</span>
      <p>If you have any questions, comments, or concerns about this Privacy Policy or our privacy practices, please contact us by email on hello@merqury.co.uk.
        You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
      </p>
    </>
  )
}

const printTOS = () => {
  return (
    <>
      <span className={styles['header-style']}> About Us</span>
      <p>Merqury Payments Limited, trading as Merqury, is a limited company registered in England and Wales with company number 14889545 (“we”, “us”, “our”, or “Merqury”).
        Our registered address is 124 City Road, London, EC1V 2NX.</p>
      <span className={styles['header-style']}> Introduction</span>
      <p>These Terms of Service (the “terms”) apply to applicants for, and holders of, a Merqury Merchant Account (an “account”), and the services supplied in relation to that account (the “services”). Please read these terms carefully before opening an account, as they form a part of the contract between us.
        By accessing or using any Merqury services you are agreeing to these terms.
        We may change all or parts of these Terms and Conditions from time to time. You agree that by accessing or using any of the Business Service at any time after such a change, you will be confirming your acceptance of the new version of these Terms and Conditions.</p>

      <span className={styles['header-style']}>Use of our Platform</span>
      <span className={styles['subheader-style']}>Account Creation</span>
      <p>In order to gain access to the Merqury platform, you must apply for an account, which can be done either via our website or by using our Android or iPhone applications.
        To be eligible for an account you must be a UK-registered business which is not ultimately owned by an overseas entity.
        Only one account is allowed per entity.
        You agree to provide the necessary information which we request during the onboarding process to enable us to carry out Know Your Customer (“KYC”) and Anti-Money Laundering (“AML”) checks. You agree that we may carry out checks on:
        Your business entity;
        Your directors; and
        Your Persons with Significant Control (“PSC”s).
        After carrying out such checks we will decide, in our sole discretion, whether to open an account for your business. We may ask you for additional documentation in order to verify your identity. We may refuse to open an account for you in the event that our KYC / AML checks give us cause for concern.
        You agree to provide details of your business bank account which can be verified by our Account Information Service Provider.</p>
      <span className={styles['subheader-style']}>Payment Initiation</span>
      <p>
        You may use your account to initiate account-to-account payments to enable your customers to make payments for goods and services procured from your business.
        You agree that you will only use your account to initiate payments for genuine purchases from the business named on the account. You agree not to use the account to initiate payments on behalf of any other entity or person, or for any purpose other than legitimate business purposes.
        If we detect that you have made fraudulent payment initiation requests, we will close your account and may hold you liable for returning funds to customers.
        Payment initiation services may be provided by a third party Payment Initiation Service Provider.
      </p>
      <span className={styles['subheader-style']}>Loyalty Programme</span>
      <p>
        At the point of opening your account, or at any time thereafter, you are able to enable the Merqury loyalty programme. If you choose to do so, you must set a Rewards Rate and a Redeem Limit. You may vary the Rewards Rate and the Redeem Limit at any time.
        The Rewards Rate determines the percentage of each transaction initiated through the Merqury platform which should be stored on behalf of the payer as loyalty points redeemable against future purchases from your business.
        The Redeem Limit is the minimum value at which that payer can redeem their points for a discount towards a purchase from your business.
        Unless otherwise notified by us in writing, any discount so given is to be funded by you.
        The rewards system available to you in the Merqury platform is known as a ‘closed’ system. This means that loyalty points earned for spending at your business shall be redeemable only at your business, and loyalty points earned at other businesses shall not be redeemable at your business.
      </p>
      <span className={styles['subheader-style']}>Account Security</span>
      <p>
        You must keep your account secure at all times. Do not disclose your password to anyone else. If you believe that an unauthorised party has gained access to your account, you must notify us immediately.
      </p>

      <span className={styles['header-style']}>Intellectual Property</span>
      <p>
        Merqury is the owner or the licensee of all intellectual property rights used in the services and any material published therein. These works are protected by copyright laws and all such rights are reserved.
        You agree not to make any use of the Merqury logo or any Merqury trademark or other intellectual property without our express consent.
        Your use of the services does not transfer any rights to the content and related intellectual property rights contained therein.
        Any data licensed to us from third parties is provided for use in the services only and may not be used for any other purposes without the consent of such third parties.
      </p>
      <span className={styles['header-style']}>Limitation of Liability</span>
      <p>
        Except as expressly and specifically provided in these terms:
        You assume sole responsibility for results obtained from your use of the services;
        We shall have no liability for any loss or damage caused as a result of errors or omissions in any information or instructions provided to us by you, or any actions taken by us at your direction;
        All warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from these terms; and
        The services are provided to you on an "as is" basis.
        We will take all reasonable endeavours to prevent unauthorised access to your Merchant Account; we accept liability for loss to you resulting from any unauthorised access to your account only insofar as such loss could have been reasonably foreseen by us, you have informed us in a timely fashion of any such loss, and you have provided any necessary information which we might reasonably require in conducting an investigation.
        You agree to be held liable for any losses arising from unauthorised access where:
        You used, attempted to use, or intended to use, services to conduct illegal activity;
        You negligently, or intentionally, failed to take all reasonable precautions to keep safe, and prevent fraudulent use of, your account;
        You acted fraudulently; or
        You were aware of the services being accessed without authority but failed to inform us promptly.
        We will not be held liable for any loss occasioned by the negligence, failure, or fraud of any third party.
        We will not be liable for the non-performance or failure to provide any part of the services occurring as a result of events which are outside of our reasonable control, for example, but not limited to: fire, telecommunications or internet failure, utility failure, power failure, equipment failure, employment strife, riot, war, terrorist attack, pandemic, non- performance of third party suppliers, or acts of God.
      </p>
      <span className={styles['header-style']}>Third Party Service Providers</span>
      <p>
        You acknowledge that certain aspects of the services are provided by Third Party Service Providers (“TPSP”s) to Merqury, and not by us directly.
        In order to ensure the smooth running of the services, you agree for us to share any data we collect from you with necessary TPSPs.
        You agree to provide us with any additional information which we may request on behalf of those TPSPs from time to time in order to ensure their continued ability to support the services provided to you.
        You further consent to any additional KYC, AML, or other business or personal checks which those TPSPs may carry out in addition to those carried out by us.
        In addition to these terms, you agree to abide my the terms and conditions of any relevant TPSP which we will notify to you.
      </p>
      <span className={styles['header-style']}>Prohibited Business Activities</span>
      <p>
        The Merqury platform may not be used for the sale, rental, trade, financing, or any other business activity related to the following Prohibited Business Activities:
        Any activity which is illegal under the law of England and Wales;
        Goods or services which infringe, or in our view reasonably appear to infringe, upon another's intellectual property rights;
        Goods and services which are themselves, or are delivered in a fashion which we believe to be, unfair, predatory, or deceptive including, but not limited to, pyramid schemes, subscription services which lack obvious retraction rights, and creation of fake online activity.
        Weapons or ammunition;
        Counterfeit or unlicensed goods;
        Drugs / narcotics;
        Personal data; or
        Financial services including, but not limited to, money transfer / money remittance services, payment facilitation, loan originations, cryptocurrencies or cryptoassets, investment / monetary asset management, and debt collection.
        We reserve the right to vary this list as necessary from time to time.
      </p>
      <span className={styles['header-style']}>Data Protection</span>
      <p>Accepting these terms shall also be taken as acceptance of our privacy policy, which can be accessed on our website.</p>
      <span className={styles['header-style']}>Right to Withdraw Service</span>
      <p>
        We may, in our sole discretion, cease provision of the services to you or otherwise suspend, withdraw, or restrict your access to the services if:
        Your registered bank account on the Merqury platform has been terminated or suspended by the relevant Account Servicing Payment Service Provider (“ASPSP”);
        We deem it necessary for operational reasons (including for security purposes);
        We deem it necessary for legal or regulatory reasons;
        We become unable to provide the services in whole or in part for any reason;
        You misuse the services or breach these terms; or
        You do not provide us with information needed for us to provide the services to you upon request.
      </p>
      <span className={styles['header-style']}> Termination </span>
      <p>
        Without prejudice to any other rights or remedies, we may terminate these Terms and Conditions at any time by providing you with written notice to your registered business address and/or the email address registered to your Merchant Account.
        Without prejudice to any other rights or remedies, either party may, by written notice to the other, terminate these terms with immediate effect in the event that:
        A party commits a material breach of these terms which is incapable of remedy;
        A party commits a material breach of these terms which is capable of remedy and fails to remedy such material breach within fourteen (14) business days after receiving written notice requiring it to remedy that material breach;
        A party suspends, or threatens to suspend, payment of its debts or is deemed unable to pay its debts within the meaning of section 123(1) Insolvency Act 1986;
        A party suspends or ceases, or threatens to suspend or cease, to carry on all or a substantial part of its business;
        A petition is presented or a resolution is passed or an order is made for the winding up, provisional winding up of a party, other than for the sole purpose of a solvent amalgamation or solvent reconstruction of that other party;
        A receiver, administrative receiver, administrator, compulsory manager or any similar officer is appointed in respect of a party or any of its assets;
        A party commences negotiations in relation to, or enters into, any arrangement, compromise, assignment or composition with its creditors or any class of them (with or without first having sought or obtained a moratorium); or
        Any event occurs, or proceeding is taken, with respect to a party in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in this clause 10.3.
        Termination will not affect any rights, remedies, obligations, or liabilities of the parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the terms which existed at or before the date of termination or expiry.
      </p>
      <span className={styles['header-style']}>General Terms</span>
      <p>
        You agree and acknowledge that all contracts, notices, information, and other communications that we provide to you pursuant to these terms electronically comply with any legal requirement that such communications be in writing.
        Any written notice to us must be sent to hello@merqury.co.uk, or other such email address as we shall from time to time notify to you.
        You may not transfer, assign, charge, or otherwise deal in any manner with any of your rights or obligations under these terms without our prior written consent.
        Other than as expressly stated in these terms, a person who is not a party herein may not enforce any of its terms, whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.
        No failure or delay by either party in enforcing its rights or remedies shall prejudice or restrict the rights or remedies of that party, and no waiver of any such rights or any breach of any contractual terms shall be deemed to be a waiver of any other right or of any later breach.
        Except as expressly provided in these terms, the rights and remedies provided herein are additional to, and not exclusive of, any rights or remedies provided by law.
        These terms constitute the entire agreement between the parties and supersedes any previous agreement whether written or oral.
        Nothing in these terms is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, or authorise any party to make or enter into any commitments for or on behalf of any other party. Each party confirms it is acting on its own behalf and not for the benefit of any other party.
        If any provision of these terms should be judged unenforceable, remaining provisions shall not be prejudiced.
        These terms and any dispute or claim arising out of or in connection with them shall be governed by the law of England and Wales. Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these terms.
      </p>
      <span className={styles['header-style']}>Changes to These Terms</span>
      <p>
        If we need to make changes to these terms, we will provide you with no less than two (2) weeks’ written notice of any change.
        If we make a change to these terms, you shall have the right to cancel the agreement and close your Merqury Merchant Account. Your continued use of the services after notification of a change to the terms shall be deemed as acceptance of such change.
      </p>
    </>
  )
}

export default LegalPopup