import React, { useContext } from 'react'
import styles from './styles/HeroPricing.module.css';
import { AiOutlineCheck } from 'react-icons/ai';
import Button from './ui/Button';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';

const CARDS = [
    {
        title: "Pay by Bank App",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>0.4% <span className={styles.lower}>per transaction</span></span>
            <span className={styles.fee}>&nbsp;</span>
        </div>,
        features: ["No Contract", "Instant Payout", "No subscription fee", "One flat rate"]
    },
    {
        title: "In-person Card Payments",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>1.0% <span className={styles.lower}> on QR Pay</span></span>
            <span className={styles.fee}>1.6% + 25p <span className={styles.lower}> on Tap to Pay</span></span>
        </div>,
        features: ["No Contract", "Weekly Payouts", "No subscription fee", "One flat rate"]
    },
    {
        title: "Card Payment Links",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>1.5% + 20p <span className={styles.lower}> <Link to="/faq/pricing-card">(Standard Cards only*)</Link></span></span>
            <span className={styles.fee}>&nbsp; </span>
        </div>,
        features: ["No Contract", "Weekly Payouts", "No subscription fee", "Payments from anywhere"]
    }
]

const HeroPricing = () => {


    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <span className={styles.tag}>
                    Join The Revolution
                    <div className={styles['circle-1']}></div>
                    <div className={styles['circle-2']}></div>
                </span>
                <h1>Ultra-Low <br />Simple <span className={styles['highlight']}>Pricing</span></h1>
                <div className={styles['card-list']}>
                    {CARDS.map((card, idx) => {
                        return (
                            <div key={idx} className={styles.card}>
                                <span className={styles.title}>{card.title}</span>
                                {card.feeComponent}
                                <div className={styles['feature-list']}>
                                    {card.features.map((item, idx) => {
                                        return (
                                            <div key={idx} className={styles['feature-item']}>
                                                <div className={styles.bullet}><AiOutlineCheck /></div>
                                                <span>{item}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Button wrapperClass={styles['app-links']} text="Join Now" onClick={() => { window.location.href = "https://merchant.merqury.co.uk/auth" }} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HeroPricing