import React from 'react'
import styles from './HowItWorksFeaturesSchool.module.css';
import { Link } from 'react-router-dom';

const HowItWorksFeaturesSchool = props => {

    const FEATURES = [
        {
            title: "Secure Payment Links",
            desc: <>Send Merqury payment links to parents to make fee payments with just a few clicks (or taps). Links are tied to your school account only for parents’ peace of mind. <Link to="/faq/security" style={{color:'var(--merquryDarkBlue)', fontWeight:600}}>Click here to learn more</Link></>,
            icon: require(`../../assets/images/how-it-works/schools/1.png`)
        },
        {
            title: "Real-Time Reporting",
            desc: "Parents get instant confirmation of payment and a digital receipt. School staff can see at a glance which parents have paid and which are outstanding.",
            icon: require(`../../assets/images/how-it-works/schools/2.png`)
        },
        {
            title: "Reduce Payment Errors",
            desc: "With no need for manual inputs, say goodbye to confirming bank account details and chasing down erroneous payments.",
            icon: require(`../../assets/images/how-it-works/schools/3.png`)
        },
        {
            title: "Autochase Payments",
            desc: "With VAT payments it has never been more important to bring in fees promptly. Let Merqury politely remind forgetful parents for you.",
            icon: require(`../../assets/images/how-it-works/schools/4.png`)
        }
    ]

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`${styles.main} main-content`}>
                <div className={styles.top}>
                    <div className={styles.left}>
                        <span className={styles.tag}>Features</span>
                        <h2 className={styles.title}>Take control of fee payments with Merqury</h2>
                        <span className={styles.desc}>Merqury fraud prevention and real-time reporting gives parents peace of mind, and saves school time.</span>
                    </div>
                    <div className={styles.right}>
                        <img alt="lamp" src={require(`../../assets/images/lamp.png`)} />
                    </div>
                </div>
                <div className={styles.bottom}>
                    {FEATURES.map((item, idx) => {
                        return (
                            <div key={idx} className={styles['grid-item']}>
                                <div className={styles['left']}><img alt="icon" src={item.icon}/></div>
                                <div className={styles['right']}>
                                    <span className={styles.title}>{item.title}</span>
                                    <span className={styles.dec}>{item.desc}</span>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}
export default HowItWorksFeaturesSchool