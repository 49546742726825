import React from 'react'
import styles from './styles/PricingPage.module.css';
import TopBar from '../components/navigation/TopBar';
import HeroPricing from '../components/HeroPricing';
import Footer from '../components/footers/Footer';
import CTAMerchant from '../components/footers/CTAMerchant';

const PricingPage = props => {
    return (
        <div className={styles.main}>
            <TopBar activeMenuItem="business" activeSubMenuItem="pricing" />
            <HeroPricing />
            <CTAMerchant/>
            <Footer/>
        </div>
    )
}

export default PricingPage